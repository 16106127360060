import './index.css'

function Home() {
    return (
        <div className="home">
            <h1>Welcome</h1>
        </div>
    )
}

export default Home